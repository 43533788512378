import React from "react"

import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MobileStepper from "@mui/material/MobileStepper"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import SwipeableViews from "react-swipeable-views"
import { StaticImage } from "gatsby-plugin-image"

const images = [
  {
    label: `Loan amount is ₹30,00,000 with interest rate 7.8% for tenure of 20 years (240 months)
    and first EMI starts in January 2022.
    Without prepayments, during entire tenure you will endup
    paying loan interest about same as initial princicple amount
    and loan closure happens on Dec 2041 and total payable amount is ₹59,32,744`,
  },
  {
    label: `But if you plan to go a little extra and pay just one monthly EMI amount as prepayment
    every 12 months, then you can greatly save overall interest amount and can make loan closure
    earlier than total tenure.
    As you can see, loan closure happens on Dec 2038 and total payable amount is ₹54,18,829`,
  },
  {
    label: `Let's take it a step ahead. If you can manage to increase prepayment amount
    little bit year on year, then it would help drastically as you can see in below sample
    prepayment plan. One monthly EMI amount every 12 months with increase in amount every 2nd time by EMI amount.
    Here, you can see, loan closure happens on Jan 2035 and total payable amount is ₹49,45,348`,
  },
  {
    label: `Finally this is how your prepayment schedule looks like
    Remember, "Little drops of water make the mighty ocean!"`,
  },
]

const ScheduledPrepayments = () => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = images.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ maxWidth: { sm: 800, md: 1080, lg: 1080 }, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 130,
          pl: 1,
          pb: 3,
          bgcolor: "background.default",
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/scheduled-prepay/1_NoPrepays.png"
            quality={100}
            alt="Loan payment schedule without prepayments"
          />
        </div>
        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/scheduled-prepay/2_FixedPrepay.png"
            quality={100}
            alt="One month EMI amount as prepayment every year"
          />
        </div>
        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/scheduled-prepay/3_PrepayWithStepUp.png"
            quality={100}
            alt="One month EMI amount with step up every second time"
          />
        </div>
        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/scheduled-prepay/4_StepupIncrement.png"
            quality={100}
            alt="Overall prepayment schedule"
          />
        </div>
      </SwipeableViews>
    </Box>
  )
}

export default ScheduledPrepayments
